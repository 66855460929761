import { render, staticRenderFns } from "./VersionLog.vue?vue&type=template&id=5e616b06&scoped=true&"
import script from "./VersionLog.vue?vue&type=script&lang=js&"
export * from "./VersionLog.vue?vue&type=script&lang=js&"
import style0 from "./VersionLog.vue?vue&type=style&index=0&id=5e616b06&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e616b06",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VApp,VBtn,VCard,VCardText,VIcon,VListItem,VListItemContent,VListItemTitle,VMain,VNavigationDrawer,VToolbar})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5e616b06')) {
      api.createRecord('5e616b06', component.options)
    } else {
      api.reload('5e616b06', component.options)
    }
    module.hot.accept("./VersionLog.vue?vue&type=template&id=5e616b06&scoped=true&", function () {
      api.rerender('5e616b06', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/VersionLog.vue"
export default component.exports