var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "version-log-page" } },
    [
      _c(
        "v-main",
        { staticClass: "pt-0", attrs: { fluid: "", tag: "section" } },
        [
          _c("TopBar", {
            attrs: { title: "Version Log" },
            on: { "show-notifications": _vm.onShowNotifications }
          }),
          _c(
            "v-navigation-drawer",
            {
              style: { "z-index": _vm.$vuetify.breakpoint.xsOnly ? 10 : 1 },
              attrs: {
                app: !_vm.$vuetify.breakpoint.xsOnly,
                right: "",
                permanent: _vm.showNotificationPane,
                "hide-overlay": "",
                width: "375px",
                stateless: "",
                absolute: _vm.$vuetify.breakpoint.xsOnly
              },
              model: {
                value: _vm.showNotificationPane,
                callback: function($$v) {
                  _vm.showNotificationPane = $$v
                },
                expression: "showNotificationPane"
              }
            },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "#3F51B5" } },
                [
                  _c(
                    "v-list-item",
                    { attrs: { "two-line": "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "white--text" },
                            [_vm._v(" Notifications ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", text: "" },
                      on: {
                        click: function($event) {
                          _vm.showNotificationPane = false
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "white" } }, [
                        _vm._v(" " + _vm._s(_vm.mdiClose) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "mx-auto mt-3 pb-8 px-3",
              staticStyle: { "max-width": "800px" }
            },
            _vm._l(_vm.releaseNotes, function(n) {
              return _c(
                "div",
                { key: n.version },
                [
                  _c("div", { staticClass: "my-3 pt-8 pb-1" }, [
                    _vm._v(
                      " Version " +
                        _vm._s(n.version) +
                        " - " +
                        _vm._s(n.date) +
                        " "
                    )
                  ]),
                  _c(
                    "v-card",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "black--text" },
                        [
                          _c(
                            "div",
                            {
                              class:
                                n.notes && n.notes.length > 0 ? "pb-5" : "pb-0",
                              staticStyle: { "font-size": "16px" }
                            },
                            [_vm._v(" " + _vm._s(n.summary) + " ")]
                          ),
                          _vm._l(n.notes, function(note) {
                            return _c(
                              "div",
                              {
                                key: note.description,
                                staticClass: "d-flex align-start py-4"
                              },
                              [
                                note.type
                                  ? _c("img", {
                                      staticClass: "pr-5 pb-5",
                                      attrs: {
                                        height: "60",
                                        width: "60",
                                        src: require("@/assets/" +
                                          (note.type === "Bug"
                                            ? "bug-check.svg"
                                            : "star-circle-outline.svg"))
                                      }
                                    })
                                  : _vm._e(),
                                _c("div", [
                                  note.title
                                    ? _c("h3", {
                                        staticClass: "pb-3",
                                        domProps: {
                                          innerHTML: _vm._s(note.title)
                                        }
                                      })
                                    : _vm._e(),
                                  note.description
                                    ? _c("div", {
                                        staticStyle: { "font-size": "16px" },
                                        domProps: {
                                          innerHTML: _vm._s(note.description)
                                        }
                                      })
                                    : _vm._e(),
                                  note.imageSrc
                                    ? _c("div", { staticClass: "pt-6 pb-2" }, [
                                        _c("img", {
                                          staticStyle: {
                                            border: "3px darkgrey solid",
                                            "border-radius": "6px",
                                            "box-shadow":
                                              "0 2px 4px 0 rgba(0, 0, 0, 0.2),"
                                          },
                                          attrs: { src: note.imageSrc }
                                        })
                                      ])
                                    : _vm._e()
                                ])
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }