<template>
  <v-app id="version-log-page">
    <v-main fluid tag="section" class="pt-0">
      <TopBar title="Version Log" @show-notifications="onShowNotifications" />

      <v-navigation-drawer
        :app="!$vuetify.breakpoint.xsOnly"
        v-model="showNotificationPane"
        right
        :permanent="showNotificationPane"
        hide-overlay
        width="375px"
        stateless
        :absolute="$vuetify.breakpoint.xsOnly"
        :style="{ 'z-index': $vuetify.breakpoint.xsOnly ? 10 : 1 }"
      >
        <v-toolbar color="#3F51B5">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="white--text">
                Notifications
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-btn icon text @click="showNotificationPane = false">
            <v-icon color="white">
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </v-toolbar>
      </v-navigation-drawer>

      <div class="mx-auto mt-3 pb-8 px-3" style="max-width: 800px">
        <div v-for="n of releaseNotes" :key="n.version">
          <div class="my-3 pt-8 pb-1">
            Version {{ n.version }} - {{ n.date }}
          </div>
          <v-card class="pa-4">
            <v-card-text class="black--text">
              <div
                style="font-size: 16px"
                :class="n.notes && n.notes.length > 0 ? 'pb-5' : 'pb-0'"
              >
                {{ n.summary }}
              </div>
              <div
                class="d-flex align-start py-4"
                v-for="note of n.notes"
                :key="note.description"
              >
                <img
                  v-if="note.type"
                  class="pr-5 pb-5"
                  height="60"
                  width="60"
                  :src="
                    require(`@/assets/${
                      note.type === 'Bug'
                        ? 'bug-check.svg'
                        : 'star-circle-outline.svg'
                    }`)
                  "
                />

                <div>
                  <h3 v-if="note.title" v-html="note.title" class="pb-3"></h3>
                  <div
                    v-if="note.description"
                    v-html="note.description"
                    style="font-size: 16px"
                  ></div>
                  <div class="pt-6 pb-2" v-if="note.imageSrc">
                    <img
                      :src="note.imageSrc"
                      style="
                        border: 3px darkgrey solid;
                        border-radius: 6px;
                        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
                          0 3px 15px 0 rgba(0, 0, 0, 0.1);
                      "
                    />
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/app/TopBar.vue";
import { mdiClose } from "@mdi/js";
import releaseNotes from "@/constants/releaseNotes";

export default {
  components: {
    TopBar,
  },
  data() {
    return {
      showNotificationPane: false,
      mdiClose,
      releaseNotes,
    };
  },
  methods: {
    onShowNotifications() {
      this.showNotificationPane = true;
    },
    onResize() {
      const [elHtml] = document.getElementsByTagName("html");
      if (this.$vuetify.breakpoint.xsOnly) {
        elHtml.style.overflowY = "auto";
        document.body.style.overflowY = "auto";
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "auto";
  },
};
</script>

<style scoped>
#version-log-page {
  background-color: #fafafa;
}
</style>
